import React from 'react';
import './App.css';

const App = () => {
  return (
    <div>
      <header>
        <div className="nav-container">
          <img src="/images/logo.png" alt="โลโก้บริษัท" className="logo" />
          <ul className="nav-links">
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="https://www.facebook.com/cnd.multipp">Contact Us</a></li>
          </ul>
          <a href="https://www.facebook.com/cnd.multipp" className="btn nav-btn">รับบริการ</a>
        </div>
      </header>

      <section id="home" className="hero">
        <h2>ครบวงจรงานดิจิทัล สร้างสรรค์ทุกไอเดีย</h2>
        <a href="https://www.facebook.com/cnd.multipp" className="btn">รับข้อเสนอพิเศษ</a>
      </section>

      <section id="services" className="services">
        <h3>สำรวจบริการที่หลากหลายของเรา</h3>
        <p>ที่ CMP Studio เรานำเสนอบริการครบวงจรที่ครอบคลุมทุกความต้องการของคุณ<br />ติดต่อเราวันนี้เพื่อรับข้อเสนอพิเศษ</p>
        <div className="services-grid">
          <div className="service-item">
            <img src="/images/resume-service.png" alt="Resume & CV Design" />
            <h3>Resume & CV Design</h3>
            <p>สร้างเรซูเม่และ CV ที่โดดเด่น เป็นมืออาชีพ<br />ไม่ว่าคุณจะกำลังมองหางานใหม่ หรือเพียงต้องการอัปเดตโปรไฟล์ของคุณ เราพร้อมสร้างเรซูเม่และ CV ที่ทำให้คุณเป็นที่จดจำและดึงดูดความสนใจจากผู้ว่าจ้าง</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
          <div className="service-item">
            <img src="/images/portfolio-service.png" alt="Portfolio Service" />
            <h3>Portfolio Creation</h3>
            <p>สร้างพอร์ตโฟลิโอที่โดดเด่นและมืออาชีพ<br />เพื่อให้ผลงานของคุณถูกนำเสนอในแบบที่ดีที่สุด ไม่ว่าจะเป็นการสมัครงาน การโปรโมทธุรกิจ หรือการแสดงความสามารถ เราพร้อมที่ทำให้คุณโดดเด่นและน่าจดจำ</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
          <div className="service-item">
            <img src="/images/Presentation-Design.jpeg" alt="Presentation Design" />
            <h3>Presentation Design</h3>
            <p>สร้างพรีเซนเทชั่นที่โดดเด่นและน่าจดจำ<br />ไม่ว่าจะเป็นการนำเสนองานในที่ประชุม การประชุมสัมมนา หรือการนำเสนอโปรเจคสำคัญ เราพร้อมสร้างพรีเซนเทชั่นที่ทำให้คุณและผลงานของคุณโดดเด่นเหนือใคร</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
          <div className="service-item">
            <img src="/images/Landing-Page-Design.png" alt="Landing Page Design" />
            <h3>Landing Page Design</h3>
            <p>สร้าง Landing Page ที่ดึงดูดใจลูกค้า<br />เพิ่มโอกาสในการขายและสร้างความประทับใจแรกกับผู้เยี่ยมชมเว็บไซต์ของคุณ เราพร้อมสร้าง Landing Page ที่ทำให้ธุรกิจของคุณโดดเด่นและน่าจดจำ</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
          <div className="service-item">
            <img src="/images/Social-Media-Management.png" alt="Social Media Management" />
            <h3>Social Media Management</h3>
            <p>เพิ่มความโดดเด่นในโลกออนไลน์<br />CMP Studio พร้อมช่วยให้ธุรกิจของคุณโดดเด่นในโลกดิจิทัลด้วยบริการเขียนบทความบล็อกที่ปรับแต่ง SEO และการจัดการโซเชียลมีเดียที่มีประสิทธิภาพ</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
          <div className="service-item">
            <img src="/images/Translation-Services.png" alt="Translation Services" />
            <h3>Translation Services</h3>
            <p>สื่อสารได้อย่างไร้ขีดจำกัด<br />เพิ่มโอกาสในการสื่อสารและขยายตลาดของคุณด้วยบริการแปลภาษาที่เชื่อถือได้ เราพร้อมช่วยให้ข้อความของคุณถูกส่งต่อไปยังผู้ฟังทุกภาษาได้อย่างมีประสิทธิภาพ</p>
            <a href="https://www.facebook.com/cnd.multipp" className="btn">จองบริการ</a>
          </div>
        </div>
      </section>

      <section id="portfolio" className="portfolio">
        <h2>ผลงานของเรา</h2>
        <div className="portfolio-grid">
        <div className="portfolio-item">
        <img src="/images/careprocc.png" alt="carepro" />
        <h3>การออกแบบเว็บไซต์สำหรับการดูแลผู้สูงอายุ</h3>
        <p>ออกแบบเว็บไซต์ CarePro-Connect ให้ใช้งานง่ายและรองรับทุกอุปกรณ์ เพื่อการเข้าถึงข้อมูลและบริการอย่างมีประสิทธิภาพ</p>
      </div>
    <div className="portfolio-item">
      <img src="/images/presentation.png" alt="Mockup Sample" />
      <h3>การออกแบบพรีเซนเทชั่นสำหรับการนำเสนอโปรเจค</h3>
      <p>ออกแบบพรีเซนเทชั่นให้ลูกค้า เพื่อการนำเสนอโปรเจคกับนายทุน เพิ่มความน่าสนใจและโอกาสในการได้รับการสนับสนุน</p>
      
    </div>
    <div className="portfolio-item">
      <img src="/images/preresume.png" alt="Mockup Sample" />
      <h3>ผลงานการออกแบบ Resume & CV</h3>
      <p>ผลงานแสดงถึงความสามารถในการสร้าง Resume & CV ที่ดึงดูดและเป็นมืออาชีพ</p>
    </div>
  </div>
</section>


<section id="testimonials" className="testimonials">
  <h2>เสียงจากลูกค้าของเรา</h2>
  <div className="testimonial-image">
    <img src="/images/review.png" alt="เสียงจากลูกค้า" />
  </div>
</section>

      <section id="contact" className="contact">
      <div className="contact-container">
        <h2>ติดต่อเรา</h2>
          <form>
          <label>ชื่อ</label>
          <input type="text" name="name" required />
          <label>อีเมล</label>
          <input type="email" name="email" required />
          <label>ข้อความ</label>
          <textarea name="message" required></textarea>
          <button type="submit" className="btn">ส่งข้อความ</button>
        </form>
        <div className="contact-info">
          <p>เชียงใหม่, 50130 | โทรศัพท์: 065-538 9953 | chanida.cndbl@gmail.com</p>
          </div>
          </div>
      </section>

      <footer>
        <ul className="footer-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#https://www.facebook.com/cnd.multipp">Services</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
        <div className="social-links">
          <a href="https://facebook.com/cnd.multipp">Facebook</a>
          <a href="https://www.linkedin.com/in/chanida-cndbl/">LinkedIn</a>
        </div>
        <p>&copy; 2024 CMP Studio. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
